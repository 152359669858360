<template>
  <hcc-modal
    name="edit-modal"
    :clickToClose="false"
    :title="$t('configuration.schedules.edit', { day: schedules.name })"
    @confirm="saveUpdate"
    @cancel="$emit('cancel', schedules)"
    @closed="clear"
  >
  <template #header>
      <div class="schedules__modal-header">
        <span class="schedules__modal-title">{{ edit }}</span>
        <span><span :class="allSelected">*</span>
          {{ $t('configuration.schedules.replicate')}}
        </span>
        <hcc-toggle-switch
          size="xs"
          :value="allDays"
          :name="'toggleAll'"
          @change="handleSelectAll"
        />
      </div>
    </template>
    <transition-group name="slide-up" v-if="dayTimes.length > 0">
      <div v-for="(time, index) in dayTimes" :key="time.id" class="edit">
        <div class="edit-item">
          <label class="schedules__title">
            {{ $t("configuration.schedules.start") }}
          </label>
          <time-picker
            class="picker"
            :input-width="55"
            :show-meridian="false"
            :controls="false"
            v-model="time.start"
          >
          </time-picker>
        </div>
        <div class="edit-item">
          <label class="schedules__title">
            {{ $t("configuration.schedules.end") }}
          </label>
          <time-picker
            class="picker"
            :input-width="55"
            :show-meridian="false"
            :controls="false"
            v-model="time.end"
          >
          </time-picker>
        </div>
        <div class="edit-options">
            <hcc-button-icon
              v-if="index === 0"
              @click="addNew"
               color="gray-light"
               square
              :disabled="!canAddSchedule"
              v-tooltip="'Add'"
            >
              <plus-icon />
            </hcc-button-icon>
            <hcc-button-icon v-else
              @click="deleteItem(time.id)"
              v-tooltip="'Delete'"
            >
              <delete-icon/>
            </hcc-button-icon>
          </div>
      </div>
    </transition-group>
  </hcc-modal>
</template>

<script>
import { TimePicker } from 'uiv';

export default {
  props: {
    schedules: {
      required: true,
    },
  },
  components: {
    TimePicker,
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  data() {
    return {
      allDays: false,
      dayTimes: [],
      error: false,
      weekday: 1,
      defaultSchedule: {
        start: new Date('January 01, 2021 09:00:00'),
        end: new Date('January 01, 2021 13:00:00'),
      },
    };
  },
  watch: {
    schedules(sch) {
      this.schedules = sch;
      this.dayTimes = [];
      this.setScheduleValues();
    },
  },
  computed: {
    canAddSchedule() {
      return this.dayTimes.length < 3;
    },
    edit() {
      return this.$t('configuration.schedules.edit', { day: this.schedules.name });
    },
    allSelected() {
      return this.allDays ? 'title_warning' : '';
    },
  },
  mounted() {
    this.setScheduleValues();
  },
  methods: {
    setScheduleValues() {
      this.weekday = this.schedules.day;
      if (this.schedules.values && (this.schedules.values.length >= 1)) {
        this.dayTimes = this.schedules.values.map(({ start, end }, id) => ({
          id,
          weekday: this.weekday,
          start: new Date(parseInt(start, 10)),
          end: new Date(parseInt(end, 10)),
        }));
      } else {
        this.dayTimes.push(this.create());
      }
    },
    create() {
      return {
        ...this.defaultSchedule,
        weekday: this.weekday,
        id: this.randomID(),
      };
    },
    saveUpdate() {
      this.error = this.validNoOverlap();
      const list = this.dayTimes.map(({ start, end, weekday }) => ({
        start,
        end,
        weekday,
      }));
      const day = this.allDays ? 'all' : this.weekday;
      this.$emit('save', { error: this.error, list, day });
    },
    addNew() {
      this.dayTimes.push(this.create());
    },
    deleteItem(id) {
      this.dayTimes = this.dayTimes.filter(sch => sch.id !== id);
    },
    validNoOverlap() {
      if (this.dayTimes.length > 1) {
        let validSch = this.formatTimes(this.dayTimes);
        validSch = validSch.map(sch => this.endLessStart(sch.start, sch.end));
        validSch.sort((a, b) => ((a.start > b.start) ? 1 : -1));

        if (validSch.length === 3) {
          const case1 = this.compare(validSch[0], validSch[1]);
          const case2 = this.compare(validSch[0], validSch[2]);
          const case3 = this.compare(validSch[1], validSch[2]);
          return !(case1 && case2 && case3);
        }
        return !this.compare(validSch[0], validSch[1]);
      }
      return false;
    },
    endLessStart(start, end) {
      return start > end ? {
        start, end: parseInt(`1${end}`, 10),
      } : { start, end };
    },
    compare(a, b) {
      return a.end < b.start && b.end > a.start;
    },
    formatTimes(days) {
      return days.map(({ start, end }) => ({
        start: this.$moment(start).local().format('HHmm'),
        end: this.$moment(end).local().format('HHmm'),
      }));
    },
    handleSelectAll(event) {
      this.allDays = event;
    },
    clear() {
      this.allDays = false;
      this.dayTimes = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_edit-schedule.scss";
</style>
